import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/dashboard": [6,[2]],
		"/(app)/dashboard/alt": [7,[2]],
		"/(public)/documentation/api": [17,[4]],
		"/(app)/session/login": [8,[2]],
		"/(app)/session/logout": [9,[2]],
		"/(app)/session/reset": [10,[2]],
		"/(app)/settings": [11,[2,3]],
		"/(app)/settings/censor": [12,[2,3]],
		"/(app)/settings/remotes": [13,[2,3]],
		"/(app)/settings/routes": [14,[2,3]],
		"/(app)/settings/sounds": [15,[2,3]],
		"/(app)/settings/tokens": [16,[2,3]],
		"/(public)/stream": [18,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';